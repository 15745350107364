/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Ghalia Adzana
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 12px;
    font-family: "Roboto"; 
    position: relative;
    overflow-x: hidden;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.trigger{position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; height: .5px; }
.wrapper-head{width: 1630px;margin: 0 auto;}
.wrapper{ width: 1370px; margin: 0 auto; }
.btn{
    line-height: 40px; padding: 0 30px; @include boxSizing(border-box); color: #fff; background: #38802d; border: none; cursor: pointer;
    font-size: 13px; letter-spacing: 3px; font-weight: 400; display: inline-block; @include borderRadius(20px); text-transform: uppercase;
    position: relative;
    @include transition(0.2s all ease-out);
    &.grn{
        background: #51ba42;
    }
    &.blue{
        background: #1f295c;
    }
    &:hover{
        background: #51ba42;
        &.grn{
            background: #1f295c; color: #fff;
        }
         &.blue{
            background: #51ba42; color: #fff;
        }
    }
    &:active{ top: 2px; }
}
.pg-down{
    width: 50px; height: 50px; position: absolute; background: #fff; bottom: 8%; left:0; right: 0; @include borderRadius(100%); display: inline-block;
    margin:auto; @include boxShadow(0 2px 8px #222);
    &:before{ 
        content:''; background: url(../images/material/btn-arr-dwn.png)no-repeat center; width: 20px; height: 10px; position: absolute;
        left: 0; top: 0; bottom: 0; right: 0; margin: auto;
     }
}
.btn-categ{
    padding: 0 15px; line-height: 25px; text-transform: uppercase; font-size: 13px; color: #fff; background: #1b3c60; display: inline-block;
    @include borderRadius(5px); border:1px solid transparent; @include transition(0.2s all ease-in);
    &.event{
        background: #38802d;
    }
    &:hover{
        background: transparent; color: #1b3c60; border:1px solid #1b3c60;
        &.event{
            color: #38802d; border-color: #38802d;
        }
    }
}
.btn-detail{
    font-size: 13px; color: #38802d; text-transform: uppercase; position: relative; padding-right: 15px; margin-top: 20px; font-weight: bold;
    display: inline-block; @include transition(all ease-out 0.4s);
    &:before{ 
        content:''; background: url(../images/material/arr-gren.png)no-repeat center; width: 6px; height: 11px; 
        position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; font-weight: 600; } @include transition(all ease-out 0.4s);
    &:hover{ 
        &:before{
            right:-5px; @include transition(all ease-out 0.4s);
        }
    }
}
.btn-view{
    font-size: 13px; color: #3c6825; text-decoration: underline; text-transform: uppercase; font-weight: bold; margin-top: 30px; display: inline-block;
}
.btn-loadmore{
    background: transparent; border:1px solid #1b3c60; padding: 0 40px; line-height: 50px; display: block; margin: 0 auto; clear: both;
    @include borderRadius (8px); text-align: center; cursor: pointer; position: relative; overflow: hidden;
    span{ font-size: 16px;  color: #1b3c60; position: relative; z-index: 2; }
    &:before{ content:'';position: absolute; left: 0; right: 0; width: 0; z-index: 1;
     background:#1b3c60; display: block; height: 100%; @include transition(0.18s all ease-out); margin:auto; }
    &:hover{
        span{ color: #fff; }
        &:before{ width:100%;  @include transition(0.2s all ease-out); }
    }
}
.btn-rounded{
    position: absolute; width: 50px; height: 50px; @include borderRadius(100%); background: #3c6825; right: 5%; bottom: 5%; display: inline-block;
    z-index: 1;@include transition(.2s all ease-out);
    &:before{ content:''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; background: url(../images/material/arr-right-white.png)no-repeat center;
        width: 12px; height: 24px;
     }
     &:hover{
        background: #49a23c; @include transition(.2s all ease-out);
     }
}
/* header
----------------------------------------------------------------------------------------------*/

header{
    background: #fff; height: 100px; position: fixed; z-index: 9; width: 100%; top: 0;
    @include boxShadow(0px 0px 20px 0px #ccc);
    @include afterclear;
    .left-header{
        float: left;
       a{ display: inline-block; padding-top: 5px;}
    }
    .right-header{
        float: right; 
        @include afterclear;
        nav{
            float: left;
           > ul{ 
               > li{
                    font-size: 14px; text-transform: uppercase;float: left;padding: 0 17.5px;position: relative;
                    &:before{
                        content:'';position:absolute; bottom: 0; left: 0; right: 0; width: 0; background: #1f295c; height: 2px;
                        @include transition(0.2s all ease-out);
                    }
                    &:hover,&.active{
                        &:before{ width:100%; @include transition(0.1s all ease-out); }
                        ul{
                            visibility:visible; opacity: 1; @include transition(0.2s all ease-out);
                        }
                    }
                    > a{  display: block; color: #111; font-weight: 500;line-height: 100px;}
                    > ul{
                        background: #fff;position: absolute;top: 100%;left:-50px; ;right: 0;  margin-top: -4px;
                        width: 200px; border:1px solid #cccccc; border-top: 4px solid #1f295c ; text-align: center;
                        visibility: hidden;  opacity: 0; 
                        &:before{
                            content:''; position: absolute; top: -10px; left: 0; right: 0; border-bottom: 10px solid #161b66;
                             border-left: 12px solid transparent; border-right: 12px solid transparent; display: block;
                             width: 0; height: 0; margin: auto; 
                        }
                       > li{ 
                            display: block; margin:20px 0;
                            a{ color: #666666; font-size: 16px; font-weight: bold; }
                            &:hover,.acitve{
                                a{ color:#1f295c;  }
                            }
                        }
                    }
                }
            }
        }
        .search{
            float: left; position: relative; line-height: 100px;
            a{
                background: url(../images/material/ic-search.png)no-repeat bottom; width: 15px; height: 15px;position: relative; cursor: pointer;
                 display: inline-block; margin-right: 25px;
            }
            .search-drop{
                background: #fff;position: absolute;top: 100%;left:-160px; ;right: 0;  margin-top: -4px; display: none;
                width: 200px; border:1px solid #cccccc; border-top: 4px solid #1f295c ; text-align: center; line-height: 0;
                input[type='text']{
                    width: 100%; @include boxSizing(border-box); top:0; font-size: 16px;
                }
                 &:before{
                    content:''; position: absolute; top: -10px;  right: 20px; border-bottom: 10px solid #161b66;
                     border-left: 12px solid transparent; border-right: 12px solid transparent; display: block;
                     width: 0; height: 0; margin: auto; 
                }
                @include placeholder{ font-size: 16px; font-style: italic; color: #666; }
            }
        }
        .lang{
            float: left; cursor: pointer; position: relative; padding-right: 15px;
            &:before{ 
                content:''; background: url('../images/material/arr-lang.png')no-repeat center; width:8px; height: 6px; position: absolute; right: 0; top: 0; bottom: 0; margin:auto 0; 
                
            }
             span{ font-size: 14px; font-weight: 500; color: #111; line-height: 100px; }
             &:hover{
                .drop{
                    visibility: visible; opacity: 1;
                }
             }
            .drop{ 
                background: #fff; position: absolute; top: 100%; width: 40px; border: 1px solid #ccc; left: -10px; right: 0; border-top: 0;
                visibility: hidden; opacity: 0;
                @include boxShadow(0 0 20px -5px #000);
                > a{ 
                    display: block; font-size: 16px; margin: 10px 0; padding-bottom: 10px; text-align: center; color: #666;
                     border-bottom: 1px solid #ccc; font-weight: bold;
                    &:last-child{
                        border:none; padding: 0;
                    }
                    &:hover,&.active{ color:#161b66;  }
                 }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.home{
    .small-tittle{
        display: block; margin-bottom: 20px; font-size: 13px; color: #999999; font-weight: 800; letter-spacing: 2px; text-transform: uppercase;
    }
    h3{ font-size: 49px; font-weight: bold; color: #1b3c60; line-height: 60px; }
    h5{ font-size: 20px; color: #333333; line-height: 30px; font-weight: 300; }
    .slider-home{
        margin-top: 100px; position: relative;
        .list-slider{
             position: relative;
             img{ width: 100%; }
        }
        .desc{
            position: absolute; right: 0; top: 50%; bottom: 0; left: 0; margin:auto; width: 970px; text-align: center;
            @include transform(translateY(-50%));
        }
        small{ font-size:13px; font-weight: 800; color: #fff; letter-spacing: 2px; margin-bottom: 30px; display: block;}
        h2{ font-size:78px; font-weight: 600;  color: #fff; margin-bottom: 25px; }
        h4{ font-size:25px; font-weight: 300; color: #fff;  width: 660px; margin: 0 auto 35px; line-height: 30px; }
        .nav_info{
            position: absolute; z-index: 2; right:8%; bottom: 8%; width: 30px;
            span{ 
                color:#fff; display: block; font-size:16px;  text-align: center; margin: auto;
                &.pos{
                    padding-bottom: 10px; position: relative; margin-bottom: 10px;
                    &:after{content:''; position: absolute; bottom: 0; left: 0; right: 0; width: 20px; height: 2px; background: #fff; margin: 0 auto; display: block; text-align: center;}
                }
            }
        }
        .slick-dots{ display: none !important; }
    }
    &.service{
        padding: 150px 0;
        .left{ width: 47%; }
        .right{ width: 47%; margin-top: 40px; }
        .box-desc{
            margin-bottom: 85px;
        }
        .box-service{
            @include afterclear;
            margin: 0 -40px;
            .list-service{
                width: 25%; float: left; padding: 0 40px; @include boxSizing(border-box);
                .titt{
                    padding-right: 70px; position: relative;
                    .bullet{ 
                        background:#1b3c60; width: 30px; height: 30px; position: absolute;  @include borderRadius(100%); display: inline-block;
                        right: 10px; top: 0; z-index: 1; @include transition(0.2s all ease-out);
                        &:before{
                            content:''; position: absolute; right: 0; top: 0; bottom: 0; left: 0; margin: auto; width: 6px; height: 12px; z-index: 2;
                            background: url(../images/material/btn-arr-dwn-s.png)no-repeat center; 
                        }
                    }
                   .t-s{  font-size: 31px; color: #111;display: block; margin-bottom: 20px; }
                   small{ font-size:16px; color: #666666; font-weight: 300;display: block; margin-bottom: 20px; line-height: 20px;  }
                }
                a{ border-top: 1px solid #999; display: block; padding-top: 40px; @include transition(0.2s all ease-out); }
                &:hover{
                    a{ border-top: 3px solid #1b3c60; }
                    .titt{
                        .bullet{
                            right: 0;
                        }
                        .t-s{ color:#1b3c60;  }
                    }
                }
            }
        }
    }
    &.product{
        background: #efefef; padding: 110px 0;
        .top-titt{
            text-align: center; margin-bottom: 75px;
            h3{ margin-bottom: 25px; }
        }
        .box{
            margin: 0 -35px;
            @include afterclear;
            .list-product{
                width: 33.333%; padding: 0 35px; float: left; @include boxSizing(border-box); 
                .images{
                    position: relative; z-index: 1; @include borderRadius(10px); overflow: hidden;
                    &:before{ 
                        content:''; position: absolute; top: 0; bottom: 0; right: 0; left: 0; background: url(../images/material/shd-prod.png)no-repeat center;
                        width: auto; height: auto; z-index: 2;
                     }
                     img{ @include transition(0.2s all ease-out); }
                     &:hover{
                        img{  @include transform(scale(1.1)); filter: brightness(100%); }
                     }
                     .btn-rounded{
                        z-index: 2;
                     }
                }
                .capt{
                    position: absolute; left: 40px; top: 30px; z-index: 3; color: #fff; right:40px;
                    h4{ margin-bottom:25px;  font-size: 31px; font-weight: bold;      }
                    small{ font-size: 13px; line-height: 20px; }
                } 
            }
        }
    }
    &.qoutes{
         width: 100%; padding: 90px 0; @include boxSizing(border-box); @include afterclear; position: relative;
          background-size: cover !important;
        .small-tittle{
            text-align: center; margin-bottom: 40px; color: #cccccc;
        }
        .profile{
            float: left; width: 16.260%; text-align: center; margin-right: 4%;
            figure{
                 margin-bottom: 20px;
                 img{  @include borderRadius(100%);margin: auto; }
            }
            span{ display: block; margin-bottom: 10px; color: #fff; font-size: 16px;}
            i{ font-size: 16px; color: #fff; }

        }
        .txt{
            float: left; width: 62%; position: relative;
            &:before{ content:'“'; position: absolute; left:-40px; top: -10px; font-size: 90px; color: #ddd; text-transform: uppercase;  }
            i{ font-size: 25px; color: #fff; line-height: 35px; font-weight: 200; font-style: italic; }
        }
        .slider{
            .slick-dots{ display: none !important; }
        }
        .slider-qts{ }
        .nav_info{
            position: absolute; z-index: 2; right:8%; bottom: 0%; width: 30px; top: 0; margin:auto 0; height: 50px;
            span{ 
                color:#fff; display: block; font-size:16px;  text-align: center; margin: auto;
                &.pos{
                    padding-bottom: 10px; position: relative; margin-bottom: 10px;
                    &:after{content:''; position: absolute; bottom: 0; left: 0; right: 0; width: 20px; height: 2px; background: #fff; margin: 0 auto; display: block; text-align: center;}
                }
            }
        }
        .arr-right{
            background: url(../images/material/arr-right-white.png)no-repeat center; width:12px; height: 24px; position: absolute; right:15%; top: 0; bottom: 0;
            z-index: 2; margin: auto 0; cursor: pointer;
        }
        .arr-left{  }

        .slick-arrow{
            &.slick-prev{ display: none !important; }
        }
    }
    &.news{
        padding: 170px 0 150px; @include boxSizing(border-box);

        .btn-detail{ 
            position: absolute; bottom: 0; left: 35px; right: 35px; display: inline-block; margin-top: 0; 
            &:before{ right:30%; }
        }
        .box-news{
            @include afterclear;
            .desc-new{
                width: 20%; float: left;
            }
            .wrap-news{
                float: right; margin: 0 -35px; width: 67.1532%;
                .list-news{
                    float: left; width: 33.333%; padding: 0 35px; @include boxSizing(border-box); height: 350px; position: relative; 
                    overflow: hidden;
                }
                figure{
                  @include borderRadius(10px); overflow: hidden; position: relative;
                  img{ width: 100%; }
                }
                .row{
                    margin: 10px 0; border-bottom: 1px solid #cccccc; padding-bottom: 10px;
                }
                .right{
                    .date{
                        line-height: 25px; font-size: 13px; color: #666;
                    }
                }
                .slick-arrow{
                   position: absolute; width:20px; height: 10px; background: url(../images/material/btn-arr-dwn.png)no-repeat center;
                   cursor: pointer; right:-2%; top: 0; bottom: 0; margin: auto; border: none; font-size: 0; background-size: 100%;
                  @include transform(rotate(-90deg));
                    &.slick-prev{
                        left:-2%; right: auto; @include transform(rotate(90deg));
                    }
                }
            }
            .desc-date{
                margin: 10px 0 0;
                .tgl{
                    font-size: 13px; color: #666666; position: relative; padding-left: 15px; padding-right:5px; margin-bottom: 5px;
                    display: block; margin-bottom: 5px;
                    &:before{ 
                        content:''; position: absolute; left:0; top: 0; bottom: 0; 
                        background: url(../images/material/ic-date.png)no-repeat center; width: 11px; height: 10px; margin: auto 0;
                    }
                    /*&:after{ content: ''; width: 2px; height: 100%; background: #ccc; position: absolute; right: 0; bottom: 0; top: 0;  }*/
                }
                .lok{
                    font-size: 13px;  color: #666; padding-left: 15px; position: relative; margin-left: 5px;
                    &:before{ content:''; position: absolute; left: 0; top: 0; bottom: 0; margin:auto 0; width: 7px; height: 10px; background: url(../images/material/ic-loc.png)no-repeat center;  }
                }
            }
        }
        h6{ font-size:16px; color: #333; line-height: 20px; height:60px; overflow: hidden;  }
    }
    &.contact{
        background-size: auto 100% !important; padding: 160px 0 170px; position: relative; z-index: 1; overflow: hidden;
        background: #efefef ;
        .wrap{
            width: 370px; margin-bottom: 20px;
        }
        .wrapper{ position: relative; z-index: 2; }
        .wrap-contact{
            padding-left: 70px; width: 600px;
            h5{ font-size:20px; color: #666666; margin-bottom: 20px;  }
            .btn{ margin-top: 40px; }
        }
        .form{
            .row{
                margin-bottom: 10px;
                .col{
                    width: 100%; position: relative;
                }
            }
            label{ position: absolute; top: 0; left:20px; bottom: 0; margin: auto 0; z-index: 2; font-size: 13px; color: #999999; 
                font-weight: bold; line-height: 60px; text-transform: uppercase;    
            }
            input[type='text'],input[type='email']{ 
                width: 100%;  position: relative; z-index: 1; padding-left: 150px; @include boxSizing(border-box); border: none; height: 60px; @include borderRadius(5px);
                 @include boxShadow(0px 0px 35px 0px #ccc); font-size: 16px; color: #333333;
            }
            textarea{
                position: relative; z-index: 1; padding-left: 20px; padding-top: 50px; @include borderRadius(5px); width: 100%; @include boxSizing(border-box); border: none;
                height: 160px; color: #333333; @include boxShadow(0px 0px 15px 0px #ccc); font-size: 16px;

            }
            @include placeholder{ font-style: italic; font-size: 16px; color: #bbb; }
        }
        .bg{
            background:url(../images/material/bg-contact.png)no-repeat right; position: absolute; display: block; right: 0; top: 0; bottom: 0;
           width: 100%;
        }
    }
    &.ourclient{
        padding: 35px 0; @inlcude boxSizing(border-box);
        .small-tittle{ text-align: center; margin-bottom: 30px; }
        .slider-client{
            @include afterclear;
            .list-slider{
                display: inline-block; margin: auto; width: 145px; height: 80px; margin-right: 40px; 
                &:last-child{ margin-right:0; }
                figure{
                    img{ filter: grayscale(100%); }
                    &:hover{
                        img{ filter: grayscale(0); }
                    }
                }
            }
            .slick-dots{
                margin: 10px auto 0; text-align: center;
                li{ 
                    display: inline-block; border: none;  margin: 0 3px; 
                    button{cursor: pointer; font-size: 0; color: transparent; width: 10px; height: 12px; @include borderRadius(100%); background: #cccccc; display: block; border: none;  }
                    &.slick-active{
                        button{ background: #707070; }
                    }
                }
            }
        }
    }
}

.form{
    .row{
        margin-bottom: 10px; @include afterclear;
        .col{
            width: 100%; position: relative;
            &.half{
                width: 49%; margin-right: 2%; float: left;
                &:last-child{ margin-right:0; }
            }
        }
    }
    label{ position: absolute; top: 0; left:20px; bottom: 0; margin: auto 0; z-index: 2; font-size: 13px; color: #999999; 
        font-weight: bold; line-height: 60px; text-transform: uppercase;    
    }
    input[type='text'],input[type='email'],input[type='number']{ 
        width: 100%;  position: relative; z-index: 1; padding-left: 140px; @include boxSizing(border-box); border: none; height: 60px; @include borderRadius(5px);
         @include boxShadow(0px 0px 35px 0px #ccc); font-size: 16px; color: #333333;
    }
    textarea{
        position: relative; z-index: 1; padding-left: 20px; padding-top: 50px; @include borderRadius(5px); width: 100%; @include boxSizing(border-box); border: none;
        height: 160px; color: #333333; @include boxShadow(0px 0px 15px 0px #ccc); font-size: 16px;

    }
    @include placeholder{ font-style: italic; font-size: 16px; color: #bbb; }
    
    .input{ width: 100%; }
    .input-file{
        text-align: center; border: 1px dashed #ccc; height: 60px; display: block; cursor: pointer; margin-top: 15px;
        @include boxSizing(border-box); @include borderRadius(5px);
        span{ 
            font-size: 16px; color: #333; vertical-align: middle; width: auto; height: 60px; display: inline-block;
            background:url(../images/material/upload.png)no-repeat left; padding-left: 25px; line-height: 60px;
            z-index: 1;
         }
        input[type='file']{ width: 100%; position: relative;z-index: 2; height: 60px; padding: 0; cursor: pointer;}
    }
       
   
}

.middle{
    padding: 100px 0;
    h3{ font-size: 39px; color:#3c6825; font-weight: bold; margin-bottom: 20px; }
    h4{ font-size:20px; font-weight: bold; color: #333333; margin-bottom: 20px;  }
    p{
        font-size: 16px; color: #666; line-height: 28px; 
        &.f-light{font-weight: 300; }
    }
    .container{
        width: 1630px; margin: 0 auto;
    }
    .wrapper{
        width: 1170px; margin: 0 auto; 
    }
    .banner-std{
        position: relative; z-index: 1;
        img{ width: 100%; }
        .qts{
            
            position: absolute; left: 0; top:25%; z-index: 2; color: #fff; bottom: 0; right:0; margin: 0 auto;
            small{ font-size: 13px; color: #fff; font-weight: 900; display: block; margin-bottom: 15px; letter-spacing: 3px;}
            span{ font-size: 78px; color: #fff; font-weight: 600; }
            .wrapper{ position: relative; }
        }
    }
    .inner-content{position: relative; z-index: 2;}
    .images-middle{
        margin:40px -100px; 
        img{ width: 100%; }
        &.no-margin{
            margin: 40px 0px;
        }
    }
    ul{
        li{
         font-size: 16px; color: #666; margin-bottom: 15px; font-weight: 300; position: relative;  padding-left: 20px; line-height: 20px;
        &:before{ content:''; width: 10px; height: 10px; background: #3c6825; @include borderRadius(100%); position: absolute; left: 0; top: 5px; }
        }
    }
}
ol{
    margin-left: 20px;
    li{
        list-style-type: decimal; padding-left: 5px; margin-bottom: 10px; font-size: 16px; font-weight: 400; line-height: 20px;
        color: #666; 
    }
}
.wrap-content{
    background: #fff; padding-top: 50px; margin-top: -205px; position: relative; z-index: 2;
    &:before{ 
        content:''; position: absolute; left:-145px; top: 0; background: #fff;  display: block; 
        height: 205px; right: -145px;
    }
    &.no-margin{ margin-top: 0; padding-top: 100px; }
    &.havepad{  }
}
.tittle-middle{
    position: relative; margin-bottom:60px;
    /*&:before{ content:''; left:-100px; top:-30px; width:90px; height: 1px; background: #122a56;position: absolute;     }*/
    h3{ font-size: 39px; color: #122a56; font-weight: bold; line-height: 40px; }
    &.no-border{
        &:before{ display:none; }
    }
    &.brd-bot{
        padding-bottom:40px;
        .row{ 
            margin-bottom: 20px; 
            .btn.right{ width: auto; }
        }
        small{ line-height:40px; margin-bottom: 0; }
        &:before{ left:0; top:auto; bottom: 0; }
    }
    .date-auth{
        span{ 
            font-size: 20px; color: #666666; margin-right: 20px; 
            &:last-child{ margin-right:0; }
        }
        .date{
            position: relative; padding-right: 20px;
            &:before{ content:''; width: 1px; background: #aaa; height: 100%; position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; }
        }
        &.news{
            .date{
                padding-left: 25px;
                &:after{
                    content:''; display: block; background: url(../images/material/ic-date-m.png)no-repeat center; position: absolute;
                    left: 0; top: 0; bottom: 0; width: 18px; height: 16px; margin: auto 0;
                }
            }
            .lok{
                position: relative; padding-left: 20px;
                &:before{ 
                    content:''; position: absolute; left: 0; top:0; bottom: 0; left: 0; 
                    background: url(../images/material/ic-lok.png)no-repeat center; width: 11px; height: 16px; margin: auto 0;
                }
            }
        }
    }
    small{ font-size: 16px; color: #666666; text-transform: uppercase; font-weight: bold; letter-spacing: 3px; display: block; margin-bottom: 15px; }
    .left{
        width: 48%;
        p{
            a{ color: #666; }
        }
    }
    .right{ width: 48%; position: relative; z-index: 3; }
    .desc-date{

        span{
         color: #666; position: relative; display: inline-block;  
             &.lok{
                padding-left: 20px;
                &:before{ 
                    content:''; background: url(../images/material/ic-lok.png)no-repeat; position: absolute; left: 0;
                    bottom: 0; width: 11px; height: 16px;
                }  
             }
        }
    }
}
.text-wrap{
    margin-bottom: 50px; position: relative; z-index: 3;
    .wrapper{
        padding: 0 100px; @include boxSizing(border-box);
    }
}
.desc-sys{
    margin-bottom: 50px; padding: 135px 0 150px; background: #efefef; position: relative; 
    &:before{ content:''; background: #efefef; position: absolute; left:-140px; top: 0; bottom: 0; height: 100%; width: 140px; display: block; right: auto;}
    h3{ margin-bottom: 55px;   }
    h4{ font-size: 31px; font-weight: bold; color: #111; margin-bottom: 15px; height: 62px; overflow: hidden;}
    .box-desc{
        @include afterclear;
        .list{
             width: 31.6%; float: left; margin-right: 2.6%; border-top:1px solid #999;
            padding-top: 20px;
            &:nth-child(3){ margin-right: 0; }
        }
    }
}
.tittle-section{
    margin-bottom: 70px;
    p{ width: 970px; }
}
.partner{ margin-bottom:140px;  }
.box-partner{
    @include afterclear;
    .list-partner{
        float: left; width: 16.666%;
    }
}
.customer{
    margin-bottom: 100px;
    .box-cs{
        @include afterclear;
        .list-cs{ float: left; width: 16.666%; }
    }
}
.sign{
    text-align: right; margin-top: 100px;
    span{ font-weight: bold; font-size: 20px; display: block; margin-top: 20px; }
}
.box-leftright{
    margin-top: 100px; 
    .box{
        @include afterclear; margin-bottom: 140px;
        figure{
         float: left; @include borderRadius(8px);  width: 48%;   margin-right: 4%;   }
        .txt{ float: left; width: 48%; padding-top: 3%;}
        i{ font-size: 31px; color: #111; font-weight: 300; line-height: 40px; }
        &:nth-child(even){ 
            figure{
                float: right; margin-right: 0;
            }
            .txt{ float: left; width: 48%; margin-right: 4%;  padding-top: 4%; margin-right: 4%;  }
        }
     }
}
.slider-newsevent { 
    margin-bottom: 110px;display: block; 
    .list-slider{ position: relative; }

}
.news-event{
    small{font-size: 13px; color: #999999; font-weight: bold; letter-spacing: 3px; margin-bottom: 30px; display: block;
     text-transform: uppercase; position: relative; z-index: 2;}
    .img-hig{
        position: relative;
        img{ @include borderRadius(8px); overflow: hidden; }
        &:before{ 
            content:''; background: url(../images/material/shdw-newsevent.png)no-repeat center; width: 100%; height: 100%;
            position: absolute;right: 0; top: 0; left: 0; bottom: 0;
         }
    }
   
    h2{ font-size: 49px; color: #fff; font-weight:bold; margin-bottom: 20px; height: 150px; overflow: hidden; }
   
    .abslt{ 
        position: absolute; left: 100px; top:10%; bottom: 0; width: 486px; 
         p{ font-size: 16px; color: #fff;  }
    }
}
.desc-date{
    margin-bottom: 30px;
    .tgl{
        font-size: 16px; color: #fff; padding-left: 20px; padding-right: 10px; position: relative;
        &:before{ content:''; background: url(../images/material/ic-date.png)no-repeat center; width: 11px; height: 10px; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0; }
        /*&:after{ content: ''; background: #ddd; width: 1px; height: 100%; position: absolute; right: 0; top: 0; bottom: 0;  }*/
     }
    .lok{
        font-size: 16px; color: #fff; padding-left: 15px; position: relative; margin-left: 10px;
        &:before{ content:''; background: url(../images/material/ic-loc-wht.png)no-repeat center; width: 7px; height: 10px; 
        position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0;  display: block;}
        
    }
}
.content-tab{
    .tab{
        margin-bottom: 40px;
        ul{
            @include afterclear; border-bottom: 1px solid #cccccc;
            li{ 
                float: left; margin-right:45px; padding-bottom: 15px; padding-left: 0; margin-bottom: 0; line-height: 1;
                &:before{ display:none; }
                &:last-child{ margin-right:0; }
                a{
                    font-size:31px; color: #666666; font-weight: bold; position: relative; @include transition(all ease-out 0.3s);

                    &:after{ content:''; width: 0; height: 3px; background:#1b3c60; position: absolute;  bottom:-15px; left: 0;  @include transition(all ease-out 0.2s); }
                    &:hover{ 
                        color:#1b3c60; 
                        &:after{
                            width:100%;
                        }
                    }
                }
             }
        }
    }
    .tab-container{ @include afterclear; }
    .center-button{
        margin: auto; text-align: center; width: 100%;
    }
}
.list-box{
    &.newsevent{
        float: left; width: 48%; margin-right: 4%; margin-bottom: 60px;
        &:nth-child(even){ margin-right: 0; }
        figure{
            @include borderRadius(8px); overflow: hidden; margin-bottom: 20px;
            img{ width: 100%; }
        }
        .row{ margin-bottom: 20px; padding-bottom: 15px; border-bottom: 1px solid #ccc; }
        .date{ font-size: 15px; color: #666666; line-height: 25px; }
        h6{ font-size: 22px; color: #333; margin-bottom: 10px; }
        p{ font-size:16px; color:#666; line-height: 24px;   }
        .desc-date{ 
            .tgl{ 
                color: #666; font-size: 14px; display: block; margin-bottom: 5px;
                &:after{ display:none; }
            }
            .lok { 
                color: #666; font-size: 14px; margin-left: 0;  position: relative; 
                &:before{ 
                    content:''; background: url(../images/material/ic-loc.png)no-repeat center; 
                    width: 7px; height: 10px; margin: auto 0; position: absolute; left: 0; top: 0; bottom: 0;
                }
            }
        }
    }
}
.border-back{
    @include afterclear; margin: 40px 0 60px;  padding-bottom: 25px; border-bottom: 1px solid #ccc; 
    a{
        font-size:16px; color: #38802d; text-transform: uppercase; letter-spacing: 2px; font-weight: 900; position: relative; text-align: right; display: inline-block; float: right;
        padding-left: 20px;
        &:before{ 
            content:''; background:url(../images/material/arr-gren.png)no-repeat center; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0; width: 6px; height: 11px;
            @include transform(rotate(180deg));  @include transition(0.2s all ease-out);
        }
        &:hover{ 
            &:before{ left:-5px;   @include transition(0.2s all ease-out);}
        }

    }
}
.box-tittle-form{
    margin-bottom: 40px;
    h2{ font-size: 39px; color: #51ba42; font-weight: 900;  }
    .right{
        small{ font-size: 13px; color:#999; margin-bottom: 15px; display: block; text-align: right; text-transform: uppercase; font-weight: 900; letter-spacing: 3px; }
        span{
           font-size: 16px; color: #35506e; display: block;  font-weight: 500;
        }
    }
}
.submit-mid{
    text-align: center; display: block; margin: auto; margin-top: 40px;
}
.section-form{
    background: #efefef; position: relative; padding: 50px 0; margin:40px 0;

}

.wrap-job{
    margin-top: 100px;
    .list-job{
        padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px solid #ccc;
    }
    .job{ display: block; color: #111; font-weight: bold; font-size: 25px; margin-bottom: 10px;}
    .date-post{ font-size:13px; color: #666; }
    .right{
        span{
            width: 40px; height: 40px; border:1px solid #666; background: transparent; @include borderRadius(100%); display: block;
            position: relative;
            &:before{ content:''; background: url(../images/material/arr.png)no-repeat center; width:8px; height: 16px; position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto;  }
        }
    }
}
.search-result{
    input[type='text']{
        font-size: 49px; color: #1f295c; font-weight: bold; position: relative; width: 100%; border: none; border-bottom: 1px solid #1f295c;
        padding-right: 65px; height: 80px; @include boxSizing(border-box);
    }
    .search-text{ margin-bottom: 60px; }
    small{ font-size:16px; color: #666666; margin-top: 20px; display: block;    }
    .input{
        position: relative; width: 100%; height: auto;
        input[type='submit']{
         width: 46px; height: 46px; background: #51ba42 url(../images/material/ic-search-wht.png) no-repeat center; @include borderRadius(100%); display: block; position: absolute; right: 0; bottom: 0; top: 0; margin: auto 0; 
         text-indent: -999px; cursor: pointer; 
        }
    }
}
.box-srch{
    a{ font-size: 20px; color: #333; margin-bottom: 15px; display: block; font-weight: bold; }
    .list-srch{
        margin-bottom:30px; border-bottom: 1px solid #ccc; padding-bottom: 15px;
        p{ margin-bottom: 0; }
    }
}
.paging{
    text-align: center; margin: 80px auto 0;
    .arr{
        color: #1b3c60;  border: 1px solid #1b3c60; background: transparent; padding: 0 20px; @include borderRadius(8px);
        &.prev{
            margin-right: 40px;
        }
        &.next{
            margin-left: 40px;
        }
        &.disable{
           border-color: #999999; color: #999999;
           &:hover{
                border-color: #999999; color: #999999; background: transparent;
           }
        }
    }
    a{ 
        line-height: 40px; display: inline-block; font-size: 16px; margin: 0 10px; padding: 0 20px; font-size: 16px; color: #333333;
        @include borderRadius(8px); @include transition(0.2s all ease-out);
        &:hover,&.active{
            background:#38802d; color: #fff; border-color: #38802d; @include transition(0.2s all ease-out);
        }
    }
}
.menumobile{ display: none; }
.alert{
    background: #1f295c; color: #fff;text-align: center; font-size: 14px; padding: 15px; @include boxSizing(border-box);
    margin-bottom: 20px; @include transition(.2s all ease-out);
    strong{ color: #5cce4b; }
}
.tittle-otherbox{
    margin-bottom: 45px;
    h4{ font-size: 31px; color: #1b3c60; margin: 0; }
}

.under-con{
    background-image: url("../images/material/bg-under.jpg"); height: 100vh; width: 100%; object-fit: cover; background-position: center; position: relative;
    .wrapper{ 
        padding-top: 360px; box-sizing: border-box;
        .text-content{
            width: 550px;
            h3{ font-weight: 300; line-height: 40px; }
            h1{ font-weight: 700; line-height: 73px; color: #1b3c60;  }
            p{ font-weight: 400; line-height: 40px; font-size: 20px; color: #666666; }
        }
        .form{
            input, textarea{ width: 600px; @include boxSizing(border-box); background: #fff; border: none;
                font-size: 13px; color: #666666; font-weight: bold; padding: 10px 0;letter-spacing: 2px; padding:20px 30px; margin-bottom: 10px; border-radius: 10px; resize: none;}
            .error{ font-weight: bold; line-height: 0;}
            .btn{ width: 220px; font-weight: bold; color: #fff; background: #38802d; margin-top: 10px; border-radius: 300px; cursor: pointer; }
        }
    }
    .logo{ position: absolute; top: 15%; left: 14%;}
}
.thanks-pop{
    position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: none;
    .overlay{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 3; cursor: pointer;}
    .inner{
        position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); z-index: 4; background: #fff; width: 500px; padding: 80px 40px 60px; @include boxSizing(border-box);
        text-align: center; font-size: 16px; line-height: 24px;
        h1{font-size: 50px; color: #af813d; margin-bottom: 30px;}
        p{line-height: 24px;}
    }
}
/* footer
----------------------------------------------------------------------------------------------*/
footer{
    padding-top: 100px; border-top: 1px solid #ccc;
    h5{ font-size:20px; font-weight: 900; text-transform: uppercase; margin-bottom: 20px;   }
    .top{
        padding-bottom: 85px; border-bottom: 1px solid #ccc;
        .left{
            width: 58.39%;
            .box{
                float: left; margin-right: 70px; 
                &:last-child{ margin-right:0; }
                a{ 
                    display: block; font-size: 16px; color: #666; margin-bottom: 15px; 
                    &:hover{ color:#1f295c; }
                }
            }
        }
        .right{
            width: 29.197%; text-align: right;
            .logo{ display: block; margin-top: -40px;  margin-bottom: 20px;}
            .addrs{
             font-size: 16px; color: #666; line-height: 20px;
                a{ color: #666; }
            }
        }
    }
    .bot{
        text-align: center; padding: 45px 0;
        span{ font-size: 16px; color: #999999;  text-transform: uppercase;}
        a{ color: #999; font-size: 16px; }
    }
}